import React, { useState, useContext, useRef } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import format from "date-fns/format";
import locale from "date-fns/locale/sv";
import base64 from "base-64";
import utf8 from "utf8";

import { AuthContext } from "../../context";
import { BACKEND_URL } from "../../config";
import { Loading, CardExpander } from "../../components";
import { useFetch } from "../../utils";

import {
  Button,
  ButtonOutline,
  Container,
  Card,
  Row,
  Column,
  Item,
  Label,
  Text,
  TextPrimary,
  TextSecondary,
  Line,
  Spacer,
  Textarea
} from "../../styles";

import { ReactComponent as CommentSVG } from "../../images/comment.svg";
import { ReactComponent as EmailSVG } from "../../images/email.svg";
import { ReactComponent as ToSVG } from "../../images/arrow-right.svg";

const CommentIcon = styled(CommentSVG)`
  fill: ${props => props.theme.blue};
  width: 30px;
  height: 38px;
  padding-top: 8px;
  margin-right: 6px;
`;

const EmailIcon = styled(EmailSVG)`
  fill: ${props => props.theme.blue};
  width: 30px;
  height: 38px;
  padding-top: 2px;
  margin-right: 6px;
`;

const ToIcon = styled(ToSVG)`
  fill: ${props => props.theme.blue};
  width: 20px;
  height: 14px;
  padding-top: 2px;
  margin-right: 0px;
`;

const Comments = styled.div`
  display: flex;
  flex-direction: column;
`;

const CommentForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderComments = props => {
  const { user } = useContext(AuthContext);
  const userDecoded = JSON.parse(utf8.decode(base64.decode(user)));
  const { orderId } = props;
  const [{ data: comments, loading }, refetchComments] = useFetch({
    path: `/comments/${orderId}`
  });
  const [comment, setComment] = useState("");
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const { setError } = useContext(AuthContext);
  const [replyToComment, setReplyToComment] = useState("");
  const [emailComment, setEmailComment] = useState("");

  const commentScrollTarget = useRef(null);

  const handleSetNewComment = e => {
    const { value } = e.target;
    setComment(value);
  };

  const handleSetNewEmailComment = e => {
    const { value } = e.target;
    setEmailComment(value);
  };

  const handleToggleOpen = () => {
    if (!open) {
      setTimeout(() => {
        commentScrollTarget.current.scrollIntoView({
          behavior: "smooth"
        });
      }, 200);
    }

    setOpen(!open);
  };

  const handleSaveNewComment = async () => {
    if (!comment) return;

    setSaving(true);

    try {
      const body = JSON.stringify({
        orderId,
        comment,
        email: ""
      });

      const res = await fetch(`${BACKEND_URL}/comment/${orderId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body
      });

      if (res.status !== 200) {
        setError(res.statusText);
        throw new Error(res.statusText);
      }

      const json = await res.json();

      if (json && json.status && json.status === 200) {
        refetchComments(new Date());
        setComment("");
      }

      setSaving(false);
    } catch (error) {
      setError(error.message);
      setSaving(false);
      console.error(error.message);
    }
  };

  const handleSaveNewEmailReply = async () => {
    if (!emailComment) return;

    setSaving(true);

    try {
      const body = JSON.stringify({
        orderId,
        comment: emailComment,
        email: replyToComment.fromEmail
      });

      const res = await fetch(`${BACKEND_URL}/comment/${orderId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        body
      });

      if (res.status !== 200) {
        setError(res.statusText);
        throw new Error(res.statusText);
      }

      const json = await res.json();

      if (json && json.status && json.status === 200) {
        refetchComments(new Date());
        setEmailComment("");
        setReplyToComment("");
      }

      setSaving(false);
    } catch (error) {
      setError(error.message);
      setSaving(false);
      console.error(error.message);
    }
  };

  if (!loading && comments) {
    return (
      <Container ref={commentScrollTarget}>
        <Card>
          <Row margin="0">
            <Column>
              <Item>
                <TextPrimary small onClick={() => handleToggleOpen()}>
                  Kommentarer
                  {comments && comments.length > 0
                    ? ` (${comments.length})`
                    : null}
                  <CardExpander open={open} />
                </TextPrimary>
                <Spacer horizontal={14} />
                <Line
                  style={{
                    display: open ? "block" : "none"
                  }}
                />
              </Item>
            </Column>
          </Row>
          <div
            style={{
              display: open ? "block" : "none"
            }}
          >
            <CommentForm>
              <Item>
                <Textarea
                  rows="5"
                  onChange={e => handleSetNewComment(e)}
                  value={comment}
                />
                <Spacer horizontal={14} />
                <Button
                  onClick={() => handleSaveNewComment()}
                  width="180px"
                  disabled={saving}
                >
                  Skicka kommentar
                </Button>
                <Spacer horizontal={14} />
                <Line />
              </Item>
            </CommentForm>
            {comments && comments.length > 0 ? (
              <Comments>
                {comments.map(comment => {
                  return (
                    <Item key={comment.id}>
                      <Row style={{ margin: 0 }}>
                        {comment.toEmail ? <EmailIcon /> : <CommentIcon />}
                        <Item style={{ margin: 0, justifyContent: "center" }}>
                          <Text style={{ textDecoration: "underline" }}>
                            {comment.createdBy.length > 25
                              ? comment.createdBy.substring(0, 25) + "..."
                              : comment.createdBy}
                          </Text>
                        </Item>
                        <Item
                          style={{
                            margin: 0,
                            justifyContent: "center",
                            alignItems: "flex-end",
                            marginLeft: "6px"
                          }}
                        >
                          <Label>
                            {format(
                              new Date(comment.created.replace(" ", "T")),
                              "do LLL HH:mm",
                              {
                                locale
                              }
                            )}
                          </Label>
                        </Item>
                      </Row>
                      {comment.toEmail && (
                        <Row style={{ margin: 0 }}>
                          <Item
                            style={{
                              margin: 0,
                              justifyContent: "center",
                              marginLeft: "42px"
                            }}
                          >
                            <Text style={{ textDecoration: "underline" }}>
                              <ToIcon />
                              {comment.toEmail}
                            </Text>
                          </Item>
                        </Row>
                      )}
                      <Row
                        style={{ marginTop: comment.toEmail ? "14px" : "4px" }}
                      >
                        <Item style={{ margin: 0 }}>
                          <Text style={{ marginLeft: "36px", color: "#000" }}>
                            {comment.text}
                          </Text>
                        </Item>
                      </Row>
                      {comment.createdBy &&
                        userDecoded.login &&
                        userDecoded.login !== comment.createdBy && (
                          <Row
                            style={{
                              marginTop: "4px",
                              display:
                                replyToComment &&
                                replyToComment.id &&
                                replyToComment.id === comment.id
                                  ? "none"
                                  : "flex"
                            }}
                          >
                            <Item style={{ margin: 0, marginLeft: "36px" }}>
                              <ButtonOutline
                                onClick={() => setReplyToComment(comment)}
                                width="75px"
                                small
                              >
                                Svara
                              </ButtonOutline>
                            </Item>
                          </Row>
                        )}
                      {replyToComment &&
                        replyToComment.id &&
                        replyToComment.id === comment.id && (
                          <CommentForm>
                            <Textarea
                              rows="5"
                              onChange={e => handleSetNewEmailComment(e)}
                              value={emailComment}
                            />
                            <Spacer horizontal={14} />
                            <div>
                              <Button
                                onClick={() => handleSaveNewEmailReply()}
                                width="100px"
                                small
                                disabled={saving}
                              >
                                Skicka svar
                              </Button>
                              &nbsp;&nbsp;&nbsp;
                              <ButtonOutline
                                onClick={() => setReplyToComment()}
                                width="60px"
                                small
                                disabled={saving}
                              >
                                Ångra
                              </ButtonOutline>
                            </div>
                            <Spacer horizontal={14} />
                          </CommentForm>
                        )}
                      <Line />
                    </Item>
                  );
                })}
              </Comments>
            ) : (
              <Row>
                <Column>
                  <Item>
                    <TextSecondary>Inga Kommentarer</TextSecondary>
                  </Item>
                </Column>
              </Row>
            )}
          </div>
        </Card>
      </Container>
    );
  } else {
    return (
      <Container>
        <Card>
          <Loading
            containerHeight="150px"
            height={80}
            text="Hämtar kommentarer..."
          />
        </Card>
      </Container>
    );
  }
};

export default withRouter(OrderComments);
