const BACKEND_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : window &&
      window.location &&
      window.location.hostname.includes("tandis.app")
    ? "https://api.tandis.app"
    : "https://tandis-api.blatornet.se";

export { BACKEND_URL };
