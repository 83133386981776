import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import { debounce } from "throttle-debounce";

import { OrderContext } from "../../context";
import { SearchInput } from "../../components";
import { TextHeader } from "../../styles";
import { Button } from "../../styles";

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 15px 7px 15px;
`;

const FilterBar = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilterResult = styled.div`
  font-size: 0.8rem;
  color: ${(props) => props.theme.grey};
  margin-top: 24px;
  margin-left: 4px;
`;

const OrderFilter = () => {
  const {
    filterOrders,
    ordersDefault,
    orders,
    filterText,
    setFilterText,
  } = useContext(OrderContext);

  const handleSetFilter = (e) => {
    const { value } = e.target;
    setFilterText(value);

    //Only Filter on words longer than 2 charcters
    if (value && value.length > 2) {
      filterOrdersDebounced(value);
    }
  };

  const handleFilter = () => {
    //Only Filter on words longer than 2 charcters
    if (filterText && filterText.length > 2) {
      filterOrdersDebounced(filterText);
    }
  };

  //Use Debounceing to only call Filter with a delay
  const filterOrdersDebounced = useCallback(
    debounce(500, (value) => {
      filterOrders(value);
    }),
    []
  );

  const handleClearFilter = () => {
    setFilterText("");
    filterOrders("");
  };

  return (
    <FilterContainer>
      <FilterBar>
        <SearchInput
          type="text"
          placeholder="Fyll i ord att filtrera..."
          name="filter"
          value={filterText}
          onChange={handleSetFilter}
          onClear={handleClearFilter}
          autoComplete="current-search"
          style={{ flex: 1 }}
        />
        <Button
          onClick={handleFilter}
          style={{ width: "80px", marginLeft: "10px" }}
          disabled={!filterText || filterText.length < 3}
        >
          Filtrera
        </Button>
      </FilterBar>
      {filterText && filterText.length > 0 ? (
        <FilterResult>
          {orders && orders.length >= 0 && (
            <TextHeader>{`${orders.length} ordrar matchar din filtrering`}</TextHeader>
          )}
        </FilterResult>
      ) : (
        <FilterResult>
          {ordersDefault && ordersDefault.length > 0 && (
            <TextHeader>{`Du har ${ordersDefault.length} öppna order`}</TextHeader>
          )}
        </FilterResult>
      )}
    </FilterContainer>
  );
};

export default OrderFilter;
