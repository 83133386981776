import React, { useContext, useEffect, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import Pullable from "react-pullable";

import { OrderContext } from "../../context";
import { BACKEND_URL } from "../../config";

import {
  Loading,
  Header,
  OrderDetails,
  OrderActions,
  OrderOperations,
  OrderImages,
  OrderComments
} from "../../components";

import { Page, Container, Spacer } from "../../styles";

const Order = React.memo(props => {
  let { orderNumber } = props.match.params;
  const { setError } = useContext(OrderContext);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState();

  useEffect(() => {
    const fetchRequest = new AbortController();
    const signal = fetchRequest.signal;
    let isCanceled = false;

    const fetchOrder = async (path = `/order/${orderNumber}`) => {
      try {
        const res = await fetch(`${BACKEND_URL}${path}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include",
          signal
        });

        if (res.status !== 200) {
          throw new Error(res.statusText);
        }

        const json = await res.json();

        if (json && json.data) {
          if (!isCanceled) {
            setData(json.data);
          }
        }
        if (!isCanceled) {
          setLoading(false);
        }
      } catch (error) {
        if (!isCanceled) {
          setError(error.message);
          setLoading(false);
          console.log(error.message);
        }
      }
    };

    fetchOrder();

    return () => {
      if (fetchRequest !== undefined) {
        isCanceled = true;
        fetchRequest.abort();
      }
    };
  }, [orderNumber, setError, refetch]);

  const refetchOrder = () => {
    setRefetch(new Date());
  };

  if (loading) {
    return (
      <Container>
        <Loading text="Hämtar order..." />
      </Container>
    );
  }

  if (data) {
    return (
      <Page>
        <Header title={`Order - ${orderNumber}`} />
        <Pullable
          onRefresh={() => refetchOrder()}
          spinnerColor="#19769f"
          disabled={loading}
        >
          <OrderDetails orderNumber={orderNumber} order={data} />
          <OrderActions orderNumber={orderNumber} orderId={data.id} />
          <OrderOperations
            orderNumber={orderNumber}
            orderId={data.id}
            orderStatusId={data.statusId}
          />
          <OrderImages orderNumber={orderNumber} orderId={data.id} />
          <OrderComments orderNumber={orderNumber} orderId={data.id} />
        </Pullable>
        <Spacer horizontal="30" />
      </Page>
    );
  } else {
    return <Redirect to="/" />;
  }
});

export default withRouter(Order);
