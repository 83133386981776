import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import "react-confirm-alert/src/react-confirm-alert.css";

import { BACKEND_URL } from "../../config";
import { AuthContext } from "../../context";
import { useFetch } from "../../utils";
import { Loading, CardExpander } from "../../components";

import {
  Container,
  Card,
  Row,
  Column,
  Item,
  TextPrimary,
  Label,
  Text,
  TextHeader,
  Line,
  Spacer,
  Button,
  ButtonSecondary,
  Confirm
} from "../../styles";

const OrderOperations = React.memo(props => {
  const { orderId, orderStatusId } = props;
  const [{ data, loading }] = useFetch({ path: `/arbetsmoment/${orderId}` });
  const { user, setAuthenticated } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const userDecoded = JSON.parse(atob(user));
  let isTechnician = false;
  let showActionButtons = false;
  let selectedOperation = "";

  const handleConfirmOperation = async ({ id, action, confirmText }) => {
    if (id && action) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <Confirm>
              <TextHeader>{`Vill du ${confirmText}?`}</TextHeader>
              <br />
              <Row style={{ justifyContent: "space-around" }}>
                <ButtonSecondary
                  width="80px"
                  onClick={() => {
                    handleChangeOperation({ id, action });
                    onClose();
                  }}
                >
                  Ja
                </ButtonSecondary>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <ButtonSecondary width="80px" onClick={onClose}>
                  Nej
                </ButtonSecondary>
              </Row>
            </Confirm>
          );
        }
      });
    }
  };

  const handleChangeOperation = async ({ id, action }) => {
    if (id && action) {
      setUpdating(true);

      try {
        const res = await fetch(`${BACKEND_URL}/uppdatera-arbetsmoment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include",
          body: JSON.stringify({ id, action })
        });

        if (res.status !== 200) {
          throw new Error(res.statusText);
        }

        const json = await res.json();

        if (json) {
          if (json.status === 200)
            props.history.go(props.history.location.pathname);
        }

        setUpdating(false);
      } catch (error) {
        if (
          error.message === "Unauthorized" ||
          Object.keys(error).length === 0
        ) {
          setAuthenticated(false);
        } else {
          setUpdating(false);
          console.log(error.message);
        }
      }
    } else {
      setUpdating(false);
    }
  };

  if (!loading && data && orderStatusId) {
    if (data.length > 0) {
      if (userDecoded.roles.indexOf("technician") > -1) isTechnician = true;

      if (
        isTechnician &&
        orderStatusId >= 300 &&
        orderStatusId < 900 &&
        orderStatusId !== 500
      ) {
        showActionButtons = true;
      }

      return (
        <Container>
          <Card>
            <Row margin="0">
              <Column>
                <Item>
                  <TextPrimary onClick={() => setOpen(!open)} small>
                    Arbetsmoment
                    {data && data.length > 0 && " (" + data.length + ")"}
                    <CardExpander open={open} />
                  </TextPrimary>
                  <Spacer horizontal={14} />
                  <Line
                    style={{
                      display: open ? "block" : "none"
                    }}
                  />
                </Item>
              </Column>
            </Row>
            <div
              style={{
                display: open ? "block" : "none"
              }}
            >
              {data.map((operation, index = 0) => {
                if (
                  showActionButtons &&
                  !selectedOperation &&
                  (operation.status === "Planned" ||
                    operation.status === "Started" ||
                    operation.status === "Paused")
                ) {
                  selectedOperation = operation.id;
                }

                return (
                  <div key={operation.id}>
                    <Row>
                      <Column>
                        <Item>
                          <Label>Namn</Label>
                          <Text>
                            {index + 1 + ". "}
                            {operation.name}
                          </Text>
                        </Item>
                      </Column>
                      <Column>
                        <Item
                          style={{
                            alignItems: "flex-end"
                          }}
                        >
                          {!updating && selectedOperation === operation.id && (
                            <div>
                              {(operation.status === "Planned" ||
                                operation.status === "Paused") && (
                                <Button
                                  onClick={() =>
                                    handleConfirmOperation({
                                      id: operation.id,
                                      action: "TASKSTART",
                                      confirmText: "starta"
                                    })
                                  }
                                  width="90px"
                                  disabled={updating}
                                >
                                  <img
                                    src="/images/play.png"
                                    height="11"
                                    width="11"
                                    alt="Play"
                                  />
                                  &nbsp;Starta
                                </Button>
                              )}
                              {operation.status === "Started" && (
                                <Button
                                  onClick={() =>
                                    handleConfirmOperation({
                                      id: operation.id,
                                      action: "TASKPAUS",
                                      confirmText: "pausa"
                                    })
                                  }
                                  width="80px"
                                  small
                                  disabled={updating}
                                >
                                  <img
                                    src="/images/pause.png"
                                    height="11"
                                    width="11"
                                    alt="Pause"
                                  />
                                  &nbsp;Paus
                                </Button>
                              )}
                              &nbsp;
                              {operation.status === "Started" && (
                                <Button
                                  onClick={() =>
                                    handleConfirmOperation({
                                      id: operation.id,
                                      action: "TASKSTOP",
                                      confirmText: "stoppa"
                                    })
                                  }
                                  width="80px"
                                  disabled={updating}
                                >
                                  <img
                                    src="/images/stop.png"
                                    height="11"
                                    width="11"
                                    alt="Stop"
                                  />
                                  &nbsp;Stop
                                </Button>
                              )}
                            </div>
                          )}
                        </Item>
                      </Column>
                    </Row>
                    <Row>
                      <Column>
                        <Item>
                          <Label>Avdelning</Label>
                          <Text>{operation.department}</Text>
                        </Item>
                      </Column>
                      {operation.assignee && (
                        <Column>
                          <Item>
                            <Label>Hanteras av</Label>
                            <Text>{operation.assignee}</Text>
                          </Item>
                        </Column>
                      )}
                    </Row>
                    <Row style={{ marginBottom: 0 }}>
                      <Item>
                        <Line />
                      </Item>
                    </Row>
                  </div>
                );
              })}
            </div>
          </Card>
        </Container>
      );
    } else {
      return null;
    }
  } else {
    return (
      <Container>
        <Card>
          <Loading
            containerHeight="150px"
            height={80}
            text="Hämtar arbetsmoment..."
          />
        </Card>
      </Container>
    );
  }
});

export default withRouter(OrderOperations);
