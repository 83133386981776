import React, { createContext, useState, useEffect, useCallback } from "react";
import base64 from "base-64";
import utf8 from "utf8";

import { usePersistedState } from "../utils";
import { BACKEND_URL } from "../config";

const AuthContext = createContext();
const OrderContext = createContext();

const AppContext = ({ children }) => {
  const [authenticated, setAuthenticated] = usePersistedState(
    "tandisAuth",
    false
  );
  const [user, setUser] = usePersistedState("tandisUser", null);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [ordersDefault, setOrdersDefault] = useState(null);
  const [orders, setOrders] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchOrders, setSearchOrders] = useState([]);
  const [searched, setSearched] = useState(false);
  const [error, setError] = useState(null);

  const [ordersHasMore, setOrdersHasMore] = useState(false);

  const fetchMoreOrders = () => {
    if (filterText) {
      const filterResult = ordersDefault.filter(
        (order) =>
          order &&
          JSON.stringify(order).toLowerCase().includes(filterText.toLowerCase())
      );

      setOrdersHasMore(orders.length + 10 > filterResult.length ? false : true);
      setOrders(filterResult.slice(0, orders.length + 10));
    } else {
      setOrdersHasMore(
        orders.length + 10 > ordersDefault.length ? false : true
      );
      setOrders(ordersDefault.slice(0, orders.length + 10));
    }
  };

  const filterOrders = useCallback(
    (text) => {
      if (text) {
        const filterResult = ordersDefault.filter(
          (order) =>
            order &&
            JSON.stringify(order).toLowerCase().includes(text.toLowerCase())
        );

        setOrdersHasMore(false);
        setOrders(filterResult);
      } else {
        if (ordersDefault && ordersDefault.length > 10) {
          setOrdersHasMore(true);
          setOrders(ordersDefault.slice(0, 10));
        } else {
          setOrdersHasMore(false);
          setOrders(ordersDefault);
        }
      }
    },
    [setOrders, ordersDefault]
  );

  const fetchDefaultOrders = useCallback(async () => {
    try {
      setLoadingOrders(true);
      let qual;

      if (user) {
        const { name, roles } = JSON.parse(utf8.decode(base64.decode(user)));

        if (
          name &&
          roles &&
          roles.length > 0 &&
          roles.indexOf("dentist") > -1
        ) {
          qual = `'StatusID'&lt;1000 AND 'DentistFullName'="${name}"`;
        } else {
          qual = `('StatusID' &lt; 900 OR 'StatusID' = 900 AND 'Modified Date' &gt; $TIMESTAMP$ - 86400 * 30) AND NOT (('StatusID' = 50 OR 'StatusID' = 150 OR 'StatusID' = 500) AND  'Modified Date' &lt; $TIMESTAMP$ - 86400 * 30)`;
        }
      } else {
        //throw new Error("No Valid User");
      }

      const res = await fetch(`${BACKEND_URL}/orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          qual,
          limit: 1000,
        }),
      });

      if (res.status !== 200) {
        setError(res.statusText);
        throw new Error(res.statusText);
      }

      const { status, data, error } = await res.json();

      if (status === 200) {
        if (data.length > 10) {
          setOrdersHasMore(true);
          setOrders(data.slice(0, 10));
        } else {
          setOrdersHasMore(false);
          setOrders(data);
        }
        setOrdersDefault(data);
        setLoadingOrders(false);
      } else {
        setError(error);
        setLoadingOrders(false);
      }
    } catch (e) {
      setError(e.message);
      setLoadingOrders(false);
    }
  }, [user]);

  const defaultAuthContext = {
    authenticated,
    setAuthenticated,
    user,
    setUser,
    error,
    setError,
  };

  const defaultOrderContext = {
    loadingOrders,
    setLoadingOrders,
    orders,
    setOrders,
    fetchMoreOrders,
    ordersHasMore,
    ordersDefault,
    setOrdersDefault,
    filterOrders,
    filterText,
    setFilterText,
    fetchDefaultOrders,
    searchText,
    setSearchText,
    searchOrders,
    setSearchOrders,
    searched,
    setSearched,
    error,
    setError,
  };

  useEffect(() => {
    if (authenticated) {
      fetchDefaultOrders();
      setFilterText("");
    } else {
      setAuthenticated(false);
      setOrdersDefault([]);
      setOrders([]);
    }
  }, [fetchDefaultOrders, authenticated, setAuthenticated, setOrdersDefault]);

  return (
    <AuthContext.Provider value={defaultAuthContext}>
      <OrderContext.Provider value={defaultOrderContext}>
        {children}
      </OrderContext.Provider>
    </AuthContext.Provider>
  );
};

export { AppContext, AuthContext, OrderContext };
