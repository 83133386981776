import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { AuthContext } from "../../context";
import { LoginForm } from "../../components";

const Login = props => {
  const { authenticated } = useContext(AuthContext);

  return (
    <div>{authenticated ? <Redirect to="/" /> : <LoginForm {...props} />}</div>
  );
};

export default Login;
