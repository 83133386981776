import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { AppContext } from "./context";

import { AuthRoute, Error } from "./components";
import { GlobalStyle, theme } from "./theme";
import { Container } from "./styles";

import {
  Login,
  Logout,
  Menu,
  Home,
  Order,
  Image,
  File,
  FourOFour,
  Search,
} from "./pages";

const App = () => {
  return (
    <AppContext>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Container>
            <Error />
            <Switch>
              <Route
                exact
                path="/login"
                render={(props) => <Login {...props} />}
              />
              <AuthRoute exact path="/menu" render={() => <Menu />} />
              <AuthRoute exact path="/" render={() => <Home />} />
              <AuthRoute
                exact
                path="/order/:orderNumber"
                render={() => <Order />}
              />
              <AuthRoute exact path="/bild/:imageId" render={() => <Image />} />
              <AuthRoute exact path="/file/:fileId" render={() => <File />} />
              <AuthRoute exact path="/search" render={() => <Search />} />
              <AuthRoute exact path="/logout" render={() => <Logout />} />
              <AuthRoute render={() => <FourOFour />} />
            </Switch>
          </Container>
          <GlobalStyle />
        </BrowserRouter>
      </ThemeProvider>
    </AppContext>
  );
};

export default App;
