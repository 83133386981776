import { createGlobalStyle } from "styled-components";

export const theme = {
  white: "#fafafa",
  lightwhite: "#f0f0f0",
  black: "#000000",
  lightgrey: "#d5d5d5",
  grey: "#95989a",
  darkgrey: "#525557",
  blue: "#19769f",
  darkBlue: "#094360"
};

export const GlobalStyle = createGlobalStyle`
  /*@font-face {
    font-family: "AvenirBook";
    src: url("./fonts/Avenir-Book.ttf");
  }*/


  html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /*font-family: "AvenirBook", sans-serif;*/
    font-family: Helvetica, sans-serif;
    font-size: 100%;
  }

  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    
  }

  body {
    overscroll-behavior-y: contain;
  }

  @media (max-width: 600px) {
    body {
      -webkit-user-select: none;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
    }
  }
`;

/*
export const Mobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const Tablet = styled.div`
  display: none;

  @media (min-width: 601px) and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const Desktop = styled.div`
  display: none;

  @media (min-width: 901px) {
    display: flex;
    flex-direction: ${prop => (prop.column ? "column" : "row")};
    flex: 1;
  }
`;
*/
